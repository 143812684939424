<template>
  <div>
    <el-table :data="tableData" border stripe style="width: 100%">
      <el-table-column prop="userId" label="ID"></el-table-column
      ><el-table-column label="头像" width="200px">
        <template slot-scope="scope">
          <el-image
            style="width: 50px; height: 50px"
            :src="scope.row.avatar"
            :preview-src-list="[scope.row.avatar]"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="昵称"></el-table-column>
      <el-table-column fixed="right" label="操作" width="200">
        <template slot-scope="scope">
          <el-popconfirm @confirm="del(scope.row.userId)" title="确定删除？">
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              slot="reference"
              style="margin-left: 10px"
            ></el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import API from "../utils/request";

export default {
  name: "User",
  data() {
    return {
      options: [],
      text: "",
      user: {},
      tableData: [],
      entity: {},
      dialogFormVisible: false,
    };
  },
  created() {
    // this.user = sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : {}
    this.load();
  },
  methods: {
    load() {
      API.get(`/api/back/all/user`).then((res) => {
        this.tableData = res.data || [];
      });
    },
    del(id) {
      API.get(`/api/back/delete/user/any/one?userId=${id}`).then((res) => {
        if (res.code === 200) {
          this.$message({
            type: "success",
            message: "操作成功",
          });
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
        this.load();
      });
    },
  },
};
</script>

<style scoped>
</style>
